<template>
  <div>
    <iframe
      v-if="hasConsent && iframeSrc"
      class="h-full w-full"
      :src="iframeSrc"
      allowfullscreen
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>

    <ConsentManagerWarning
      v-else
      :can-be-activated="true"
      :foreign-domains="['maps.googleapis.com']"
      class="h-full w-full"
    />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
  address?: string
  zoom?: number
}

const { address, cinema, zoom } = defineProps<Props>()
const runtimeConfig = useRuntimeConfig()

const iframeSrc = computed(() => {
  const prefix = 'https://www.google.com/maps/embed/v1/place'

  if (address) {
    return `${prefix}?key=${runtimeConfig.public.GOOGLE_MAPS_API_KEY}&q=${address}&zoom=${zoom}`
  } else if (cinema) {
    return `${prefix}?key=${runtimeConfig.public.GOOGLE_MAPS_API_KEY}&q=${cinema.name},${getFormattedCinemaAddress(cinema)}&zoom=${zoom}`
  }

  return ''
})

const { getVendorConsent } = useConsentManager()
const hasConsent = computed(() =>
  getVendorConsent(CONSENT_MANAGER_VENDOR.GOOGLE_MAPS)
)

defineOptions({
  name: 'GoogleMaps',
})
</script>
